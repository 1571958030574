import React from "react";

function Service() {
  return (
    <section className="service-section section-block" id="service-section">
      <div className="container">
        <div className="section-title dark">
          <h2>Service</h2>
          <p className="lead">
            I provide innovative and user-centric solutions that elevate digital
            experiences and exceed client expectations
          </p>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6" data-aos="fade-up">
            <div
              className="content-wrapper service-tilt"
              data-tilt
              data-tilt-max="15"
            >
              <div className="icon-box">
                <i className="pe-7s-monitor"></i>
              </div>
              <h3 className="mb-0">
                Web <br /> Develpment
              </h3>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-6"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div
              className="content-wrapper service-tilt"
              data-tilt
              data-tilt-max="15"
            >
              <div className="icon-box">
                <i className="pe-7s-lock"></i>
              </div>
              <h3 className="mb-0">
                DApp <br /> Development
              </h3>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-6"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div
              className="content-wrapper service-tilt"
              data-tilt
              data-tilt-max="15"
            >
              <div className="icon-box">
                <i className="pe-7s-coffee"></i>
              </div>
              <h3 className="mb-0">
                Free <br /> Consulting
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="design-elements">
        <img
          className="de-service-1 elem-updown"
          src="/assets/image/design-elements/service-element-1.png"
          alt="service element 1"
        />
        <img
          className="de-service-2 elem-updown"
          src="/assets/image/design-elements/service-element-2.png"
          alt="service element 2"
        />
        <img
          className="de-service-3 elem-updown"
          src="/assets/image/design-elements/service-element-3.png"
          alt="service element 3"
        />
      </div>
    </section>
  );
}

export default Service;
