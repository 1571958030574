import React from "react";

function Sidebar() {
  return (
    <aside className="main-sidebar">
      <header className="site-header" id="site-header">
        <nav className="navbar navbar-expand-xl" id="site-navbar">
          <div className="container">
            <img
              className="img-fluid d-block d-xl-none"
              src="/assets/image/fungi_logo.png"
              alt="fungi logo"
            />
            <img
              className="d-none d-xl-block img-fluid sidenav-photo"
              src="/assets/image/sidenav-photo.jpg"
              alt="sidenav photo"
            />
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="bi bi-list"></i>
              </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="#site-header"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about-section">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#portfolio-section">
                    Portfolio
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#service-section">
                    Service
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#testimonial-section">
                    Testimonials
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="#blog-section">
                    Blog
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="#contact-section">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </aside>
  );
}

export default Sidebar;
