import React from "react";

function About() {
  return (
    <section
      className="about-section section-block"
      id="about-section"
      data-aos="fade-up"
    >
      <div className="container">
        <div className="row">
          <div
            className="col-xl-6 image-block"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="2000"
          >
            <div
              className="img-wrapper about-img-wrap"
              data-tilt
              data-tilt-max="10"
            >
              <img
                className="about-img-1 img-fluid"
                src="/assets/image/about-image-1.png"
                alt="about image"
              />
              <img
                className="about-img-2 img-fluid"
                src="/assets/image/about-image-2.png"
                alt="about image 2"
              />
            </div>
          </div>
          <div
            className="col-xl-6 content-block"
            data-aos="fade-right"
            data-aos-delay="400"
            data-aos-duration="2000"
          >
            <h2>
              <span>About Me</span>Senior Web Developer
            </h2>
            <p>
              I'm a young web developer who love to take risk. I grew up in a
              tech family in De Leon City
            </p>
            <p>
              While working as a web developer, I have built dozens of modern
              and professional websites for e-commerce, elearning, investment
              business, sports betting, consulting and more, monetizing for over
              30 companies worldwide.
            </p>
            <p>
              Especially, I have had tremendous experience as a web3 developer
              while developing several blockchain networks, cryptocurrencies,
              tokens, trading platforms, bots and different kinds of DApps such
              as DEX, DEFI, DAO and NFT marketplaces.
            </p>
            <p>
              It is me who strives passion, motivation, and a desire to work.
            </p>
            <div className="personal-details row">
              <div className="col-md-6">
                <ul className="personal-info">
                  <li>
                    <h4>Email</h4>
                    <p>owner@javiermeza.com</p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="personal-info">
                  <li>
                    <h4>Phone</h4>
                    <p>+123 456 7890</p>
                  </li>
                </ul>
              </div>
            </div>
            <a className="btn-main" href="#">
              Download CV
            </a>
          </div>
        </div>
      </div>
      <div className="design-elements">
        <img
          className="de-about-1 elem-updown"
          src="/assets/image/design-elements/about-element-1.png"
          alt="about element 1"
        />
        <img
          className="de-about-2 elem-updown"
          src="/assets/image/design-elements/about-element-2.png"
          alt="about element 2"
        />
      </div>
    </section>
  );
}

export default About;
