import React from "react";

function Hero() {
  return (
    <section className="hero-area" id="hero-area">
      <div className="container">
        <div className="hero-content d-flex justify-content-center">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-xl-6 order-xl-last image-block">
              <div className="image-wrapper" data-tilt data-tilt-max="10">
                <ul className="hero-facts">
                  <li>
                    <p>
                      50<span>+</span>
                    </p>
                    <h4>
                      Total <br />
                      Projects
                    </h4>
                  </li>
                  <li>
                    <p>
                      20<span>+</span>
                    </p>
                    <h4>
                      Worldwide <br />
                      Clients
                    </h4>
                  </li>
                </ul>
                <img
                  className="img-fluid"
                  src="/assets/image/hero_main_image.png"
                  alt="hero main image"
                />
              </div>
            </div>

            <div className="col-xl-6 order-xl-first content-block">
              <h1 className="hero-head">
                <small>Hello, I'm</small>Javier <strong>Meza</strong>
              </h1>
              <p>
                A passionate <strong>Web Developer</strong> from{" "}
                <strong>Texas!</strong>
              </p>
              <div className="link-group">
                <a className="btn-main" href="#">
                  Contact Me
                </a>
                <ul className="hero-social list-inline">
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="design-elements">
        <img
          className="de-hero-1 elem-updown"
          src="/assets/image/design-elements/hero-element-1.png"
          alt="hero element 1"
        />
        <img
          className="de-hero-2 elem-move"
          src="/assets/image/design-elements/hero-element-2.png"
          alt="hero element 2"
        />
        <img
          className="de-hero-3 elem-updown"
          src="/assets/image/design-elements/hero-element-3.png"
          alt="hero element 3"
        />
        <img
          className="de-hero-4 elem-updown"
          src="/assets/image/design-elements/hero-element-4.png"
          alt="hero element 4"
        />
        <img
          className="de-hero-5 elem-move"
          src="/assets/image/design-elements/hero-element-5.png"
          alt="hero element 5"
        />
      </div>
    </section>
  );
}

export default Hero;
