import React from "react";

function Testimonial() {
  return (
    <section
      className="testimonial-section section-block"
      id="testimonial-section"
    >
      <div className="container">
        <div className="section-title dark">
          <h2>Testimonials</h2>
          <p className="lead"></p>
        </div>
        <div className="testimonial-carousel swiper">
          <div className="swiper-wrapper">
            <div className="testimonial-item swiper-slide">
              <div className="item-wrapper">
                <div className="quote-icon">
                  <img src="/assets/image/quote-icon.png" alt="quote icon" />
                </div>
                <p>
                  Working with Javier has been an absolute pleasure. Javier is
                  not only a skilled developer but also a great communicator and
                  collaborator, making the entire process smooth and enjoyable.
                  We highly recommend him to anyone looking for top-notch
                  developer.
                </p>
                <div className="quote-author d-flex align-items-center justify-content-center">
                  <div className="image-block">
                    <img
                      className="img-fluid"
                      src="/assets/image/testimonial/testimonial-author-1.jpg"
                      alt="testimonial author"
                    />
                  </div>
                  <h4>
                    Edgar M Torres<span>CTO of Climb Exchange</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
