import React from "react";

function Contact() {
  return (
    <section className="contact-section section-block" id="contact-section">
      <div className="container">
        <div className="section-title">
          <h2>Get in Touch</h2>
          <p className="lead">Don't hesitate to contact me now.</p>
        </div>

        <div className="row contact-options">
          <div
            className="col-lg-4 d-flex justify-content-xxl-center align-items-xl-center"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <div className="icon-box">
              <i className="pe-7s-map-marker"></i>
            </div>
            <div className="content-wrapper">
              <h4>Address</h4>
              <address>409 ( W ) Manchaca Ave, De Leon, TX</address>
            </div>
          </div>
          <div
            className="col-lg-4 d-flex justify-content-xxl-center align-items-xl-center"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <i className="pe-7s-call"></i>
            </div>
            <div className="content-wrapper">
              <h4>Phone</h4>
              <a href="#">+01234 567 890</a>
            </div>
          </div>
          <div
            className="col-lg-4 d-flex justify-content-xxl-center align-items-xl-center"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            <div className="icon-box">
              <i className="pe-7s-mail"></i>
            </div>
            <div className="content-wrapper">
              <h4>Email</h4>
              <a href="#">owner@javiermeza.com</a>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-lg-6"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            <div className="map-wrapper" id="mapwrapper"></div>
          </div>
          <div
            className="col-lg-6 form-block"
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-delay="1100"
          >
            <h3>Write me a message</h3>
            <div className="form-message">
              <p></p>
            </div>
            <form
              className="row g-3"
              id="fungi-contact"
              method="POST"
              action="https://ethemestudio.com/demo/fungi/8_side_nav/php/form-handler.php"
            >
              <div className="col-md-6">
                <div className="input-group mb-3">
                  <label
                    htmlFor="inputName"
                    className="form-label visually-hidden"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Name*"
                    name="inputName"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-3">
                  <label
                    htmlFor="inputEmail"
                    className="form-label visually-hidden"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    name="inputEmail"
                    placeholder="Email*"
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <label
                  htmlFor="inputMessage"
                  className="form-label visually-hidden"
                >
                  Message
                </label>
                <textarea
                  className="form-control mb-3"
                  id="inputMessage"
                  name="inputMessage"
                  placeholder="Your message here*"
                  required
                ></textarea>
                <button type="submit" className="btn btn-main" name="submit">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="design-elements">
        <img
          className="de-contact-1 elem-updown"
          src="/assets/image/design-elements/contact-element-1.png"
          alt="contact element 1"
        />
        <img
          className="de-contact-2 elem-updown"
          src="/assets/image/design-elements/contact-element-2.png"
          alt="contact element 2"
        />
      </div>
    </section>
  );
}

export default Contact;
