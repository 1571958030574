import React from "react";

function Portfolio() {
  return (
    <section className="portfolio-section section-block" id="portfolio-section">
      <div className="container">
        <div className="section-title">
          <h2>Portfolio</h2>
          <p className="lead">
            My portfolio showcases a diverse range of projects, each reflecting
            my commitment to excellence and creativity.
          </p>
        </div>
        <div className="row grid" data-aos="fade-up" data-aos-duration="2000">
          <div className="col-lg-4 col-md-6 grid-item programming">
            <div className="box">
              <img
                src="/assets/image/portfolio/portfolio-item-01.jpg"
                alt="portfolio image"
              />
              <div className="box-content">
                <span className="category">Programming</span>
                <h3 className="title">Website design for Rainy Design</h3>
              </div>
              <div className="icon-box">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#portfolioModal6"
                >
                  <i className="bi bi-plus-lg"></i>
                </a>
              </div>

              <div
                className="modal fade"
                id="portfolioModal6"
                tabIndex="-1"
                aria-labelledby="portfolioModal6"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                      <div className="row item-content">
                        <div className="col-xl-12">
                          <img
                            src="/assets/image/portfolio/portfolio_large_1.jpg"
                            alt="portfolio image"
                          />
                        </div>
                        <div className="col-xl-8">
                          <div className="content-wrapper">
                            <h2 className="item-title">
                              Branding for ABC Corporation
                            </h2>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat. Excepteur
                              sint occaecat cupidatat non proident, sunt in
                              culpa qui officia deserunt mollit anim id est
                              laborum.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Excepteur sint occaecat cupidatat non
                              proident, sunt in culpa qui officia deserunt
                              mollit anim id est laborum.
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <div className="meta-wrapper">
                            <ul className="item-meta">
                              <li>
                                Project Type:{" "}
                                <span className="meta-value">
                                  Graphic Design
                                </span>
                              </li>
                              <li>
                                Client:{" "}
                                <span className="meta-value">Juwel Khan</span>
                              </li>
                              <li>
                                Duration:{" "}
                                <span className="meta-value">2 Weeks</span>
                              </li>
                              <li>
                                Task:{" "}
                                <span className="meta-value">
                                  UI/UX, Frontend
                                </span>
                              </li>
                              <li>
                                Budget:{" "}
                                <span className="meta-value">$2000</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 grid-item development application">
            <div className="box">
              <img
                src="/assets/image/portfolio/portfolio-item-02.jpg"
                alt="portfolio image"
              />
              <div className="box-content">
                <span className="category">Development</span>
                <h3 className="title">Come on babe light my fire</h3>
              </div>

              <div className="icon-box">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#portfolioModal5"
                >
                  <i className="bi bi-plus-lg"></i>
                </a>
              </div>

              <div
                className="modal fade"
                id="portfolioModal5"
                tabIndex="-1"
                aria-labelledby="portfolioModal5"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                      <div className="row item-content">
                        <div className="col-xl-12">
                          <img
                            src="/assets/image/portfolio/portfolio_large_2.jpg"
                            alt="portfolio image"
                          />
                        </div>
                        <div className="col-xl-8">
                          <div className="content-wrapper">
                            <h2 className="item-title">
                              Branding for ABC Corporation
                            </h2>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat. Excepteur
                              sint occaecat cupidatat non proident, sunt in
                              culpa qui officia deserunt mollit anim id est
                              laborum.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Excepteur sint occaecat cupidatat non
                              proident, sunt in culpa qui officia deserunt
                              mollit anim id est laborum.
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <div className="meta-wrapper">
                            <ul className="item-meta">
                              <li>
                                Project Type:{" "}
                                <span className="meta-value">
                                  Graphic Design
                                </span>
                              </li>
                              <li>
                                Client:{" "}
                                <span className="meta-value">Juwel Khan</span>
                              </li>
                              <li>
                                Duration:{" "}
                                <span className="meta-value">2 Weeks</span>
                              </li>
                              <li>
                                Task:{" "}
                                <span className="meta-value">
                                  UI/UX, Frontend
                                </span>
                              </li>
                              <li>
                                Budget:{" "}
                                <span className="meta-value">$2000</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 grid-item application programming design">
            <div className="box">
              <img
                src="/assets/image/portfolio/portfolio-item-03.jpg"
                alt="portfolio image"
              />
              <div className="box-content">
                <span className="category">Design</span>
                <h3 className="title">
                  When the musics over turn off the light
                </h3>
              </div>
              <div className="icon-box">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#portfolioModal4"
                >
                  <i className="bi bi-plus-lg"></i>
                </a>
              </div>

              <div
                className="modal fade"
                id="portfolioModal4"
                tabIndex="-1"
                aria-labelledby="portfolioModal4"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                      <div className="row item-content">
                        <div className="col-xl-12">
                          <img
                            src="/assets/image/portfolio/portfolio_large_3.jpg"
                            alt="portfolio image"
                          />
                        </div>
                        <div className="col-xl-8">
                          <div className="content-wrapper">
                            <h2 className="item-title">
                              Branding for ABC Corporation
                            </h2>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat. Excepteur
                              sint occaecat cupidatat non proident, sunt in
                              culpa qui officia deserunt mollit anim id est
                              laborum.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Excepteur sint occaecat cupidatat non
                              proident, sunt in culpa qui officia deserunt
                              mollit anim id est laborum.
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <div className="meta-wrapper">
                            <ul className="item-meta">
                              <li>
                                Project Type:{" "}
                                <span className="meta-value">
                                  Graphic Design
                                </span>
                              </li>
                              <li>
                                Client:{" "}
                                <span className="meta-value">Juwel Khan</span>
                              </li>
                              <li>
                                Duration:{" "}
                                <span className="meta-value">2 Weeks</span>
                              </li>
                              <li>
                                Task:{" "}
                                <span className="meta-value">
                                  UI/UX, Frontend
                                </span>
                              </li>
                              <li>
                                Budget:{" "}
                                <span className="meta-value">$2000</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 grid-item design application">
            <div className="box">
              <img
                src="/assets/image/portfolio/portfolio-item-04.jpg"
                alt="portfolio image"
              />
              <div className="box-content">
                <span className="category">Application</span>
                <h3 className="title">She's got a smile that seems to me</h3>
              </div>
              <div className="icon-box">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#portfolioModal3"
                >
                  <i className="bi bi-plus-lg"></i>
                </a>
              </div>

              <div
                className="modal fade"
                id="portfolioModal3"
                tabIndex="-1"
                aria-labelledby="portfolioModal3"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                      <div className="row item-content">
                        <div className="col-xl-12">
                          <img
                            src="/assets/image/portfolio/portfolio_large_4.jpg"
                            alt="portfolio image"
                          />
                        </div>
                        <div className="col-xl-8">
                          <div className="content-wrapper">
                            <h2 className="item-title">
                              Branding for ABC Corporation
                            </h2>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat. Excepteur
                              sint occaecat cupidatat non proident, sunt in
                              culpa qui officia deserunt mollit anim id est
                              laborum.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Excepteur sint occaecat cupidatat non
                              proident, sunt in culpa qui officia deserunt
                              mollit anim id est laborum.
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <div className="meta-wrapper">
                            <ul className="item-meta">
                              <li>
                                Project Type:{" "}
                                <span className="meta-value">
                                  Graphic Design
                                </span>
                              </li>
                              <li>
                                Client:{" "}
                                <span className="meta-value">Juwel Khan</span>
                              </li>
                              <li>
                                Duration:{" "}
                                <span className="meta-value">2 Weeks</span>
                              </li>
                              <li>
                                Task:{" "}
                                <span className="meta-value">
                                  UI/UX, Frontend
                                </span>
                              </li>
                              <li>
                                Budget:{" "}
                                <span className="meta-value">$2000</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 grid-item programming design">
            <div className="box">
              <img
                src="/assets/image/portfolio/portfolio-item-05.jpg"
                alt="portfolio image"
              />
              <div className="box-content">
                <span className="category">Programming</span>
                <h3 className="title">So close no matter how far</h3>
              </div>
              <div className="icon-box">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#portfolioModal2"
                >
                  <i className="bi bi-plus-lg"></i>
                </a>
              </div>

              <div
                className="modal fade"
                id="portfolioModal2"
                tabIndex="-1"
                aria-labelledby="portfolioModal2"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                      <div className="row item-content">
                        <div className="col-xl-12">
                          <img
                            src="/assets/image/portfolio/portfolio_large_5.jpg"
                            alt="portfolio image"
                          />
                        </div>
                        <div className="col-xl-8">
                          <div className="content-wrapper">
                            <h2 className="item-title">
                              Branding for ABC Corporation
                            </h2>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat. Excepteur
                              sint occaecat cupidatat non proident, sunt in
                              culpa qui officia deserunt mollit anim id est
                              laborum.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Excepteur sint occaecat cupidatat non
                              proident, sunt in culpa qui officia deserunt
                              mollit anim id est laborum.
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <div className="meta-wrapper">
                            <ul className="item-meta">
                              <li>
                                Project Type:{" "}
                                <span className="meta-value">
                                  Graphic Design
                                </span>
                              </li>
                              <li>
                                Client:{" "}
                                <span className="meta-value">Juwel Khan</span>
                              </li>
                              <li>
                                Duration:{" "}
                                <span className="meta-value">2 Weeks</span>
                              </li>
                              <li>
                                Task:{" "}
                                <span className="meta-value">
                                  UI/UX, Frontend
                                </span>
                              </li>
                              <li>
                                Budget:{" "}
                                <span className="meta-value">$2000</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 grid-item development design">
            <div className="box">
              <img
                src="/assets/image/portfolio/portfolio-item-06.jpg"
                alt="portfolio image"
              />
              <div className="box-content">
                <span className="category">Development</span>
                <h3 className="title">
                  When you are stranger faces looks ugly
                </h3>
              </div>
              <div className="icon-box">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#portfolioModal1"
                >
                  <i className="bi bi-plus-lg"></i>
                </a>
              </div>

              <div
                className="modal fade"
                id="portfolioModal1"
                tabIndex="-1"
                aria-labelledby="portfolioModal1"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                      <div className="row item-content">
                        <div className="col-xl-12">
                          <img
                            src="/assets/image/portfolio/portfolio_large_6.jpg"
                            alt="portfolio image"
                          />
                        </div>
                        <div className="col-xl-8">
                          <div className="content-wrapper">
                            <h2 className="item-title">
                              Branding for ABC Corporation
                            </h2>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua. Ut enim ad minim
                              veniam, quis nostrud exercitation ullamco laboris
                              nisi ut aliquip ex ea commodo consequat. Excepteur
                              sint occaecat cupidatat non proident, sunt in
                              culpa qui officia deserunt mollit anim id est
                              laborum.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Excepteur sint occaecat cupidatat non
                              proident, sunt in culpa qui officia deserunt
                              mollit anim id est laborum.
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <div className="meta-wrapper">
                            <ul className="item-meta">
                              <li>
                                Project Type:{" "}
                                <span className="meta-value">
                                  Graphic Design
                                </span>
                              </li>
                              <li>
                                Client:{" "}
                                <span className="meta-value">Juwel Khan</span>
                              </li>
                              <li>
                                Duration:{" "}
                                <span className="meta-value">2 Weeks</span>
                              </li>
                              <li>
                                Task:{" "}
                                <span className="meta-value">
                                  UI/UX, Frontend
                                </span>
                              </li>
                              <li>
                                Budget:{" "}
                                <span className="meta-value">$2000</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="design-elements">
        <img
          className="de-portfolio-1 elem-updown"
          src="/assets/image/design-elements/portfolio-element-1.png"
          alt="portfolio element 1"
        />
        <img
          className="de-portfolio-2 elem-updown"
          src="/assets/image/design-elements/portfolio-element-2.png"
          alt="portfolio element 2"
        />
      </div>
    </section>
  );
}

export default Portfolio;
