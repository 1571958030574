import React from "react";

function Footer() {
  return (
    <footer className="site-footer" id="site-footer">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 text-center branding-block">
            <h2 className="text-danger">Javier Meza</h2>
            <ul className="list-inline footer-social">
              <li className="list-inline-item">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="back-to-top">
          <a href="#site-header">
            <i className="bi bi-box-arrow-up"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
