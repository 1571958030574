import React from "react";

import Hero from "./components/Hero";
import Intro from "./components/Intro";
import About from "./components/About";
import Skill from "./components/Skill";
import Portfolio from "./components/Portfolio";
import Service from "./components/Service";
import Feature from "./components/Feature";
import Testimonial from "./components/Testimonial";
import Blog from "./components/Blog";
import Partner from "./components/Partner";
import Contact from "./components/Contact";
import Footer from "../../layout/footer/Footer";

function Home() {
  return (
    <main className="main-content">
      <Hero />
      <Intro />
      <About />
      <Skill />
      <Portfolio />
      <Service />
      <Feature />
      <Testimonial />
      {/* <Blog /> */}
      {/* <Partner /> */}
      <Contact />
      <Footer />
    </main>
  );
}

export default Home;
