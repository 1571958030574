import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Preloader from "./layout/preloader/Preloader";
import Sidebar from "./layout/sidebar/Sidebar";
import Home from "./pages/home/Home";

function App() {
  return (
    <>
      {/* <Preloader /> */}
      <Sidebar />
      <Home />
      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
}

export default App;
