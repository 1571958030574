import React from "react";

function Feature() {
  return (
    <section className="features-section section-block" id="features-section">
      <div className="container">
        <div className="row">
          <div
            className="col-xl-6 image-block"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div className="image-wrapper">
              <img
                className="img-fluid feature-photo"
                src="/assets/image/feature-photo.png"
                alt="features photo"
                data-tilt
                data-tilt-max="10"
              />
            </div>
          </div>
          <div
            className="col-xl-6 content-block"
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-delay="400"
          >
            <h2 className="block-title">
              <span>Why work with me</span>Best result with top user experience
            </h2>
            <p>
              My extensive expertise in the latest technologies ensures that
              your projects are built with cutting-edge solutions.
            </p>
            <ul className="features-list">
              <li className="d-flex align-items-start">
                <div className="icon-block">
                  <div className="icon-box">
                    <i className="pe-7s-umbrella"></i>
                  </div>
                </div>
                <div className="content-wrapper">
                  <h4>Innovative Solutions</h4>

                  <p>
                    I specialize in creating cutting-edge web solutions that are
                    tailored to meet your unique needs, ensuring your digital
                    presence stands out.
                  </p>
                </div>
              </li>
              <li className="d-flex align-items-start">
                <div className="icon-block">
                  <div className="icon-box">
                    <i className="pe-7s-box2"></i>
                  </div>
                </div>
                <div className="content-wrapper">
                  <h4>Commitment to Excellence</h4>

                  <p>
                    My meticulous attention to detail and dedication to
                    high-quality work guarantee that every project is delivered
                    to the highest standards.
                  </p>
                </div>
              </li>
              <li className="d-flex align-items-start">
                <div className="icon-block">
                  <div className="icon-box">
                    <i className="pe-7s-refresh-2"></i>
                  </div>
                </div>
                <div className="content-wrapper">
                  <h4>Smooth Collaboration</h4>

                  <p>
                    With strong communication skills and a collaborative
                    approach, I make the development process seamless and
                    enjoyable for all stakeholders.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Feature;
