import React from "react";

function Skill() {
  return (
    <section className="skill-section section-block" id="skill-section">
      <div className="container">
        <div className="row">
          <div
            className="col-xl-6 content-block"
            data-aos="fade-down"
            data-aos-duration="2000"
          >
            <h2>
              <span>My Skills</span>I'm great in what I do and I'm loving it
            </h2>
            <p className="lead">
              Every day, I bring passion and expertise to my work, creating web
              solutions that not only meet but exceed expectations.
            </p>
          </div>
          <div className="col-xl-6 progress-block">
            <div className="progress-wrapper">
              <h4>Frontend Development</h4>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "98%" }}
                  aria-valuenow="95"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="progress-wrapper">
              <h4>Backend Development</h4>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "85%" }}
                  aria-valuenow="85"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="progress-wrapper">
              <h4>Restful API Development</h4>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "96%" }}
                  aria-valuenow="96"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="progress-wrapper">
              <h4>Smart Contract Development</h4>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "94%" }}
                  aria-valuenow="94"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skill;
