import React from "react";

function Intro() {
  return (
    <section className="intro-section section-block">
      <div className="container">
        <div className="section-title">
          <h2>Welcome to my World</h2>
          <p className="lead">
            I am an innovative web developer with 7 years of experience building
            and maintaining responsive websites.
          </p>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6" data-aos="fade-up">
            <div className="item-wrapper">
              <div className="icon-box">
                <i className="pe-7s-science"></i>
              </div>
              <div className="content-wrapper">
                <h3>Creativity</h3>
                <p>
                  Creativity shines through in every project, transforming
                  complex ideas into visually stunning and user-friendly
                  websites.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-6"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1200"
          >
            <div className="item-wrapper">
              <div className="icon-box">
                <i className="pe-7s-diamond"></i>
              </div>
              <div className="content-wrapper">
                <h3>Dedication</h3>
                <p>
                  Dedication to continuous learning and improvement ensures that
                  me stay ahead of the latest web trends and technologies.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-6"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1200"
          >
            <div className="item-wrapper">
              <div className="icon-box">
                <i className="pe-7s-rocket"></i>
              </div>
              <div className="content-wrapper">
                <h3>Hard Work</h3>
                <p>
                  Hard work and meticulous attention to detail guarantee that
                  every line of code I write contributes to seamless and
                  efficient web experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="design-elements">
        <img
          className="de-intro-1 elem-updown"
          src="/assets/image/design-elements/intro-element-1.png"
          alt="intro element 1"
        />
      </div>
    </section>
  );
}

export default Intro;
